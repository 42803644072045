import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Logo from 'components/layout/Logo'
import ResponsiveSidebar from 'components/layout/ResponsiveSidebar'
import { NavBarMenuI } from 'context/types'

type Props = {
  firstName: string | null
  lastName: string | null
  navBarMenu: NavBarMenuI
  cv: string | null
}

const NavBar = (props: Props) => {
  const { firstName, lastName, navBarMenu, cv } = props

  return (
    <AppBar position="sticky" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo firstName={firstName} lastName={lastName} />
          <ResponsiveSidebar navBarMenu={navBarMenu} cv={cv} />
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default NavBar
