import * as React from 'react'
import { styled } from '@mui/material/styles'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GithubIcon from '@mui/icons-material/GitHub'
import { IconButtonProps } from '@mui/material/IconButton'
import { IconButton } from '@mui/material'

type Props = {
  icon: string
  link: string
}
interface CustomizeIconProps extends IconButtonProps {
  icon: string
}

const CustomizeIcon = styled(IconButton)<CustomizeIconProps>(({ icon, theme }) => {
  switch (icon) {
    case 'facebook':
      return {
        color: theme.palette.secondary.contrastText,
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.facebook.main,
          background: 'transparent'
        }
      }
    case 'linkedIn':
      return {
        color: theme.palette.secondary.contrastText,
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.linkedIn.main,
          background: 'transparent'
        }
      }
    case 'github':
      return {
        color: theme.palette.secondary.contrastText,
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.github.main,
          background: 'transparent'
        }
      }
    default:
      return {
        color: theme.palette.secondary.contrastText,
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.contrastText,
          background: 'transparent'
        }
      }
  }
})

const SocialMediaIcon = (props: Props) => {
  const { icon, link } = props
  const handleOpenLink = () => {
    const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  switch (icon) {
    case 'facebook':
      return <CustomizeIcon onClick={handleOpenLink} children={<FacebookIcon />} icon={icon} />
    case 'linkedIn':
      return <CustomizeIcon onClick={handleOpenLink} children={<LinkedInIcon />} icon={icon} />
    case 'github':
      return <CustomizeIcon onClick={handleOpenLink} children={<GithubIcon />} icon={icon} />
    default:
      return null
  }
}

export default SocialMediaIcon
