import * as React from 'react'
import {
  LayoutContextActionI,
  PersonalDataJSON,
  NavBarMenuI,
  SocialMediaI,
  Dispatch
} from './types'

interface LayoutContextStateI {
  loading?: boolean
  jsonLink: string
  jsonData: PersonalDataJSON | null
  navBarMenu: NavBarMenuI
  firstName: string | null
  lastName: string | null
  socialMedia: SocialMediaI | []
  email: string | null
  cv: string | null
}
const initialState: LayoutContextStateI = {
  loading: true,
  jsonLink: '/panagiotinos.json',
  jsonData: null,
  navBarMenu: [
    {
      title: 'Resume',
      link: '',
      isResume: true
    },
    {
      title: 'Portfolio',
      link: '/portfolio'
    }
  ],
  firstName: null,
  lastName: null,
  email: null,
  socialMedia: [],
  cv: null
}

const LayoutStateContext = React.createContext(initialState)
const LayoutDispatchContext = React.createContext<Dispatch>(() => null)

const useLayoutState = () => {
  const context = React.useContext(LayoutStateContext)
  if (context === undefined) {
    throw new Error('useLayoutState must be child of LayoutProvider')
  }
  return context
}

const useLayoutDispatch = () => {
  const context = React.useContext(LayoutDispatchContext)
  if (context === undefined) {
    throw new Error('useLayoutDispatch must be used within a LayoutProvider')
  }
  return context
}

const useLayout = () => [useLayoutState(), useLayoutDispatch()]

const layoutReducer = (
  state: LayoutContextStateI,
  action: LayoutContextActionI
): LayoutContextStateI => {
  switch (action.type) {
    case 'loading': {
      return {
        ...state,
        loading: action.loading
      }
    }
    case 'set-json-data': {
      return {
        ...state,
        jsonData: action.jsonData || null
      }
    }
    case 'set-layout': {
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        socialMedia: action.socialMedia,
        cv: action.cv
      }
    }
    default:
      return state
  }
}

type Props = {
  children: JSX.Element
}

const LayoutProvider = (props: Props) => {
  const { children } = props
  const [state, dispatch] = React.useReducer(layoutReducer, initialState)
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>{children}</LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  )
}
const fetchJSONData = async (dispatch: Dispatch, jsonLink: string) => {
  try {
    dispatch({
      type: 'loading',
      loading: false
    })
    const response = await fetch(process.env.PUBLIC_URL + jsonLink)
    const data: PersonalDataJSON = await response.json()
    dispatch({
      type: 'set-json-data',
      jsonData: data
    })
    dispatch({
      type: 'set-layout',
      cv: data.cv || null,
      firstName: data.firstName || null,
      lastName: data.lastName || null,
      email: data.email || null,
      socialMedia: data.socialMedia || []
    })
  } catch (error) {
    console.error(error)
  } finally {
    dispatch({
      type: 'loading',
      loading: false
    })
  }
}

export { LayoutProvider, useLayoutDispatch, useLayoutState, useLayout, fetchJSONData }
