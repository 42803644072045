import { Box, Typography } from '@mui/material'
import * as React from 'react'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { SocialMediaI } from 'context/types'
import SocialMediaIcon from 'components/social-media/SocialMediaIcon'
import Logo from 'components/layout/Logo'

type Props = {
  socialMedia: SocialMediaI
  firstName: string | null
  lastName: string | null
  email: string | null
}

const Footer = (props: Props) => {
  const { socialMedia, firstName, lastName, email } = props
  return (
    <Paper elevation={0} component={Stack} direction="row" justifyContent="center">
      <Paper elevation={0} component={Stack} direction="column" justifyContent="center">
        <Paper elevation={0} component={Stack} direction="row" justifyContent="center">
          <Box>
            <Logo firstName={firstName} lastName={lastName} />
          </Box>
        </Paper>
        <Paper elevation={0} component={Stack} direction="row" justifyContent="center">
          <Box>
            <Typography
              component="a"
              href={`mailto:${email}`}
              variant="subtitle1"
              color="secondary.contrastText">
              {email}
            </Typography>
          </Box>
        </Paper>
        <Paper elevation={0} component={Stack} direction="row" justifyContent="center">
          <Box>
            {socialMedia.map(({ icon, link }) => (
              <SocialMediaIcon key={icon} link={link} icon={icon} />
            ))}
          </Box>
        </Paper>
      </Paper>
    </Paper>
  )
}

export default Footer
