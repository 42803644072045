import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import NavBar from 'components/layout/NavBar'
import Footer from 'components/layout/Footer'
import { useLayoutDispatch, useLayoutState, fetchJSONData } from 'context/layout-context'
import { Container, Box, Paper, Stack } from '@mui/material'

type Props = {
  children?: JSX.Element
}

const Layout = ({ children }: Props) => {
  const { firstName, lastName, navBarMenu, socialMedia, email, loading, jsonLink, cv } =
    useLayoutState()
  const dispatch = useLayoutDispatch()
  React.useEffect(() => {
    fetchJSONData(dispatch, jsonLink)
  }, [dispatch, jsonLink])
  return loading ? (
    <Paper
      sx={{ minHeight: '90vh' }}
      elevation={0}
      component={Stack}
      direction="column"
      justifyContent="center"></Paper>
  ) : (
    <>
      <CssBaseline />
      <NavBar firstName={firstName} lastName={lastName} navBarMenu={navBarMenu} cv={cv} />
      <Container sx={{ minHeight: '80vh' }}>
        <Box>{children}</Box>
      </Container>
      <Footer email={email} lastName={lastName} firstName={firstName} socialMedia={socialMedia} />
    </>
  )
}
export default Layout
