import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from 'views/Layout'
import theme from 'theme/theme'
import { ThemeProvider } from '@mui/material/styles'
import { LayoutProvider } from 'context/layout-context'
import Home from 'views/Home'
import Photography from 'views/Photography'
import PortfolioView from 'views/PortfolioView'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LayoutProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/portfolio" element={<PortfolioView />}></Route>
          </Routes>
        </Layout>
      </LayoutProvider>
    </ThemeProvider>
  )
}

export default App
