import React from 'react'
import { NavBarMenuI, NavBarMenuItemI } from 'context/types'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'

type Props = {
  navBarMenu: NavBarMenuI
  cv: string | null
}

const ResponsiveBox = styled(Box)(({ theme }) => ({
  '&.drawer': {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inherit'
    }
  },
  '&.extras': {
    display: 'inherit',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
})) as typeof Box

const ResponsiveSidebar = (props: Props) => {
  const { navBarMenu, cv } = props
  const navigate = useNavigate()

  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [resume, setResume] = React.useState({
    title: ''
  } as NavBarMenuItemI)
  const [extras, setExtras] = React.useState(navBarMenu)

  React.useEffect(() => {
    const resumeLink = navBarMenu.find(({ isResume }) => isResume)
    if (resumeLink) {
      setResume(resumeLink)
    }
    const extrasLink = navBarMenu.filter(({ isResume }) => !isResume)
    if (extrasLink.length > 0) {
      setExtras(extrasLink)
    }
  }, [navBarMenu])

  const handleOnClick = (link: string | null, download: boolean) => {
    const newWindow = window.open(
      `${download ? process.env.PUBLIC_URL : ''}${link}`,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
  }
  const handleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }
  const handleOnNavigate = (link: string, hasDrawer = false) => {
    if (hasDrawer) {
      handleDrawer()
    }
    navigate(link)
  }
  return (
    <>
      {resume ? (
        <Button onClick={() => handleOnClick(cv, true)} variant="menu" key={resume.title}>
          {resume.title}
        </Button>
      ) : null}
      <ResponsiveBox className="extras">
        {extras.map(({ title, link }) => (
          <Button onClick={() => handleOnNavigate(link)} variant="menu" key={title}>
            {title}
          </Button>
        ))}
      </ResponsiveBox>
      <>
        <ResponsiveBox className="drawer">
          <Button onClick={handleDrawer} variant="menu">
            Menu
          </Button>
        </ResponsiveBox>
        <Drawer anchor="right" open={openDrawer} onClose={handleDrawer}>
          <List>
            {extras.map(({ title, link }) => (
              <ListItem disableGutters key={title}>
                <Button onClick={() => handleOnNavigate(link, true)} variant="menu">
                  {title}
                </Button>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </>
    </>
  )
}
export default ResponsiveSidebar
