import * as React from 'react'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Article from 'components/articles/Article'
import { withPersonal, usePersonalState } from 'context/personal-context'

const Home = () => {
  const { title, subtitle, article, avatar } = usePersonalState()
  return (
    <Paper
      sx={{ minHeight: '50vh' }}
      elevation={0}
      component={Stack}
      direction="column"
      justifyContent="center">
      <Article title={title} subtitle={subtitle} article={article} avatar={avatar} />
    </Paper>
  )
}
export default withPersonal(Home)
