import React from 'react'
import { Portfolio } from '@gpanagiotinos/react-component-library'
import Container from '@mui/material/Container'

const PortfolioView = () => {
  const portfolios = [
    {
      id: 1,
      img: `${process.env.PUBLIC_URL}/alimosphotography.png`,
      alt: 'Test Alt',
      link: 'https://tinyurl.com/alimosphotography',
      title: 'Alimos Photography Exhibition',
      label: 'NEW',
      description: 'Alimos Photography Exhibition Webpage 2022'
    }
  ]
  return (
    <Container sx={{ pt: 5 }}>
      <Portfolio portfolios={portfolios} />
    </Container>
  )
}
export default PortfolioView
