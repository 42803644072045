import * as React from 'react'
import { PersonalContextActionI, Dispatch } from './types'
import { useLayoutState } from 'context/layout-context'

interface PersonalContextStateI {
  title: string
  subtitle: string
  article: string
  avatar?: string
}
const initialState: PersonalContextStateI = {
  title: '',
  subtitle: '',
  article: '',
  avatar: ''
}

const LayoutStateContext = React.createContext(initialState)
const LayoutDispatchContext = React.createContext<Dispatch>(() => null)

const usePersonalState = () => {
  const context = React.useContext(LayoutStateContext)
  if (context === undefined) {
    throw new Error('usePersonalState must be child of LayoutProvider')
  }
  return context
}

const usePersonalDispatch = () => {
  const context = React.useContext(LayoutDispatchContext)
  if (context === undefined) {
    throw new Error('usePersonalDispatch must be used within a LayoutProvider')
  }
  return context
}

const usePersonal = () => [usePersonalState(), usePersonalDispatch()]

const personalReducer = (state: PersonalContextStateI, action: PersonalContextActionI) => {
  switch (action.type) {
    case 'set-home': {
      return {
        ...state,
        title: action.title,
        subtitle: action.subtitle,
        article: action.article,
        avatar: action.avatar
      }
    }
    default:
      return state
  }
}

type Props = {
  children: JSX.Element
}
const PersonalProvider = (props: Props) => {
  const { children } = props
  const [state, dispatch] = React.useReducer(personalReducer, initialState)
  const { jsonData } = useLayoutState()
  React.useEffect(() => {
    if (jsonData && jsonData.home && jsonData.home.article)
      dispatch({
        type: 'set-home',
        ...jsonData.home.article
      })
  }, [jsonData])
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>{children}</LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  )
}

const withPersonal = (Component: React.FC) => (props: JSX.IntrinsicAttributes) =>
  (
    <PersonalProvider>
      <Component {...props} />
    </PersonalProvider>
  )

export { PersonalProvider, usePersonalDispatch, usePersonalState, usePersonal, withPersonal }
