import { createTheme } from '@mui/material/styles';


interface CustomPalette {
    main: string
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
      menu: true;
    }
  }
declare module '@mui/material/styles' {
    interface Palette {
        facebook: CustomPalette | Palette['primary'];
        github: CustomPalette | Palette['primary'];
        linkedIn: CustomPalette | Palette['primary'];
      }
      interface PaletteOptions {
        facebook: CustomPalette | Palette['primary'];
        github: CustomPalette | Palette['primary'];
        linkedIn: CustomPalette | Palette['primary'];
      }
}
  

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffffff',
            contrastText: '#333333'
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#b3b3b3',
        },
        facebook: {
            main: '#4167b2'
        },
        linkedIn: {
            main: '#0e76a8'
        },
        github: {
            main: '#333333'
        }
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'menu' },
                    style: {
                        color: '#000000',
                        fontWeight: 700
                    }
                }
            ]
        }
    }
})

export default theme