import * as React from 'react'
import { Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

const TypographyFirstName = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.secondary.contrastText}
`
) as typeof Typography

type Props = {
  firstName: string | null
  lastName: string | null
}

const Logo = (props: Props) => {
  const { firstName, lastName } = props
  const navigate = useNavigate()

  const handleNavigateHome = () => {
    navigate('/')
  }

  return (
    <Box onClick={handleNavigateHome} sx={{ flexGrow: 1, cursor: 'pointer' }}>
      <TypographyFirstName style={{ textTransform: 'uppercase' }} component="span" fontWeight={700}>
        {firstName}
      </TypographyFirstName>
      <Typography style={{ textTransform: 'uppercase' }} component="span" fontWeight={700}>
        {lastName}
      </Typography>
    </Box>
  )
}

export default Logo
