import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import * as React from 'react'

type Props = {
  title?: string
  subtitle?: string
  avatar?: string
  article?: string
}
const Article = (props: Props) => {
  const { title, subtitle, avatar, article } = props
  return (
    <Card elevation={0} sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {title}
          </Typography>
          <Typography
            sx={{ fontWeight: 700 }}
            variant="subtitle1"
            color="text.secondary"
            component="div">
            {subtitle}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>{article}</Box>
      </Box>
      {avatar ? (
        <CardMedia
          component="img"
          sx={{ width: 151, height: 151, borderRadius: 2 }}
          image={process.env.PUBLIC_URL + avatar}
          alt="Profile"
        />
      ) : null}
    </Card>
  )
}
export default Article
